.scrollButton {
  position: absolute;
  top: 0%;
  transform: translateY(0%);
  background: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.8;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 0;
  min-width: 36px;
  min-height: 36px;
}

.scrollButton:hover {
  opacity: 1;
  background: #f8f9fa;
}

.scrollLeft {
  left: 10px;
}

.scrollRight {
  right: 10px;
}

.arrowIcon {
  display: inline-block;
  line-height: 1;
  position: relative;
  top: -1px;
}

.reviewsContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 50px 10px;
  gap: 20px;
  cursor: grab;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.reviewsContainer::-webkit-scrollbar {
  display: none;
}